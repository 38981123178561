<template>
  <ul class="block">
    <li v-for="tag in tags" :key="tag.id" class="inline-block mr-2 mb-2">
      <NuxtLink
        :to="{
          name: indexStore.currentPaperSlug ? 'paper_subject' : 'subject',
          params: { subject: tag.id, paper: indexStore.currentPaperSlug },
        }"
      >
        <CommonButton
          :bg="isMm ? 'bg-black bg-opacity-5' : 'bg-black bg-opacity-10'"
          size="medium"
          border="border-none"
        >
          {{ tag.name }}
        </CommonButton>
      </NuxtLink>
    </li>
  </ul>
</template>

<script setup lang="ts">
import type { TagDTO } from '~/typesAuto/apicore/v1'

const { isMm } = useBrand()

defineProps<{ tags: TagDTO[] }>()
const indexStore = useIndexStore()
</script>
